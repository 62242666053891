import { render, staticRenderFns } from "./NotificationTemplateEditorSidebar.vue?vue&type=template&id=340f66ac"
import script from "./NotificationTemplateEditorSidebar.vue?vue&type=script&lang=js"
export * from "./NotificationTemplateEditorSidebar.vue?vue&type=script&lang=js"
import style0 from "./NotificationTemplateEditorSidebar.vue?vue&type=style&index=0&id=340f66ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VContainer,VDivider,VRow,VTextarea})
