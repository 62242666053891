<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-container class="notification-template-editor-preview">
          <v-row>
            <v-col>
              <calendesk-information-message>
                {{ $trans("notification_templates_list_information") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2 class="text-center">
                {{ $trans("notification_templates_list_preview_title") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-container v-if="editorModalSelectedRecord.email">
                <v-row no-gutters>
                  <v-col>
                    <div
                      class="notification-template-editor-preview__email-toolbar"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-container
                      class="notification-template-editor-preview__email-content"
                    >
                      <v-row>
                        <v-col>
                          {{ $trans("from") }}: {{ $config("company_name") }}
                          {{ companyEmail }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          {{ $trans("to") }}:
                          {{ $trans("customer_example_name") }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          {{ $trans("email_subject") }}:
                          <span class="font-weight-bold">{{
                            editorModalPreviewContent.email.subject
                              | tenantVariable
                              | truncate(65)
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-divider />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div v-html="emailHTMLText" />
                        </v-col>
                      </v-row>
                      <v-row v-if="showCtaButton">
                        <v-col class="d-flex justify-center">
                          <v-btn color="#0092ff" dark depressed>
                            {{ ctaEmailButtonText }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex justify-center" cols="12">
        <v-container class="notification-template-editor-preview">
          <v-row justify="center">
            <v-col
              v-if="editorModalSelectedRecord.sms"
              class="notification-template-editor-preview__notifications_phone d-flex align-center"
              cols="6"
            >
              <notification-preview
                :body="
                  editorModalPreviewContent.sms.text
                    | tenantVariable
                    | truncate(160)
                "
                :headline="$trans('sms_title')"
                :title="$config('company_name')"
                icon="sms"
              />
            </v-col>
            <v-col
              v-if="editorModalSelectedRecord.push"
              class="notification-template-editor-preview__notifications_phone d-flex align-center"
              cols="6"
            >
              <notification-preview
                :body="
                  editorModalPreviewContent.push.text
                    | tenantVariable
                    | truncate(240)
                "
                :title="
                  editorModalPreviewContent.push.subject
                    | tenantVariable
                    | truncate(65)
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import NotificationPreview from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/components/NotificationPreview.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default Vue.extend({
  name: "NotificationTemplateEditorPreview",
  components: { CalendeskInformationMessage, NotificationPreview },
  data() {
    return {
      emailSubject: null,
      emailText: null,
    };
  },
  computed: {
    ...mapGetters({
      editorModalSelectedRecord: "notification/getEditorModalSelectedRecord",
      editorModalPreviewContent: "notification/getEditorModalPreviewContent",
    }),
    emailHTMLText() {
      return this.$options.filters.tenantVariable(
        this.editorModalPreviewContent.email.text
      );
    },
    companyEmail() {
      return this.$config("company_email")
        ? `<${this.$config("company_email")}>`
        : null;
    },
    showCtaButton() {
      return (
        this.editorModalSelectedRecord.category === "account" ||
        this.editorModalSelectedRecord.category === "newsletter"
      );
    },
    ctaEmailButtonText() {
      return this.$trans(
        `${this.editorModalSelectedRecord.category}_${this.editorModalSelectedRecord.type}_email_cta_button_${this.editorModalSelectedRecord.receiver}`
      );
    },
  },
  created() {},
  methods: {
    ...mapActions({}),
  },
});
</script>

<style lang="scss" scoped>
.notification-template-editor-preview {
  background-color: var(--v-cd_color_2-base);
}

.notification-template-editor-preview__email-content {
  background-color: var(--v-cd_color_3-base);
  padding: 20px;
}

.notification-template-editor-preview__email-toolbar {
  background-color: var(--v-cd_color_4-base);
  background-image: url("~@/assets/macos-buttons.png");
  background-position: 20px center;
  height: 43px;
  border-radius: 5px 5px 0 0;
}

.notification-template-editor-preview__notifications_phone {
  background-image: url("~@/assets/notifications-phone.png");
  width: 414px;
  max-width: 414px;
  min-width: 414px;
  height: 347px;
  max-height: 347px;
  min-height: 347px;
  margin: 1rem;
}
</style>
