<template>
  <v-dialog
    v-model="editorModalVisible"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
    class="template-editor"
  >
    <v-app-bar color="white">
      <v-img
        alt="Calendesk Logo"
        class="shrink mr-2"
        contain
        :src="
          require('@/lib/calendesk-js-library/assets/calendesk-logo-square.jpg')
        "
        transition="scale-transition"
        width="40"
      />
      <v-spacer />
      <v-btn
        color="light"
        class="mr-4"
        text
        @click="cancelUpdate"
        v-text="$trans('cancel')"
      />
      <v-btn color="blue" dark depressed @click="confirmUpdate">
        {{ $trans("ready") }}
      </v-btn>
    </v-app-bar>
    <v-container fluid tag="section" class="template-editor">
      <v-row no-gutters class="fill-height">
        <v-col cols="12" md="4">
          <notification-template-editor-sidebar />
        </v-col>
        <v-col md="8" class="d-none d-md-block">
          <notification-template-editor-preview />
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import NotificationTemplateEditorSidebar from "./components/NotificationTemplateEditorSidebar.vue";
import NotificationTemplateEditorPreview from "./components/NotificationTemplateEditorPreview.vue";
import EditorContentController from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/EditorContentController";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default Vue.extend({
  name: "NotificationTemplateEditor",
  components: {
    NotificationTemplateEditorPreview,
    NotificationTemplateEditorSidebar,
  },
  mixins: [sharedActions],
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    ...mapGetters({
      editorModalVisible: "notification/getEditorModalVisible",
      editorModalSelectedRecord: "notification/getEditorModalSelectedRecord",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      setEditorModalVisible: "notification/setEditorModalVisible",
    }),
    confirmUpdate() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("notification_template_editor_publish_title"),
        warning: this.$trans("notification_template_editor_publish_warning"),
        importantInformation: this.$trans(
          "notification_template_editor_publish_text"
        ),
        confirmationText: this.$trans(
          "notification_template_editor_publish_confirmation_button"
        ),
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          this.processUpdate();
        },
      });
    },
    processUpdate() {
      pushEvent("updateNotificationTemplate");

      EditorContentController.notificationTemplatesAPIAction("update")
        .then(() => {
          this.closeConfirmDialog();
          this.setEditorModalVisible(false);
        })
        .catch((error) => {
          errorNotification("error_occurred", error);
        })
        .finally(() => {
          this.setCommonDialogLoader(false);
        });
    },
    cancelUpdate() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("notification_template_editor_publish_cancel_title"),
        importantInformation: this.$trans(
          "notification_template_editor_publish_cancel_text"
        ),
        confirmationText: this.$trans(
          "notification_template_editor_publish_cancel_confirmation_button"
        ),
        confirmAction: () => {
          pushEvent("cancelUpdateNotificationTemplate");

          this.closeConfirmDialog();
          this.setEditorModalVisible(false);
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.template-editor {
  padding: 0;
  background-color: var(--v-cd_color_2-base);
  min-height: 100%;
}
</style>
